import styled from "styled-components";
import HeroBg from "assets/image/hero-bg.png";

export const Container = styled.div`
  width: 100%;
  min-height: 320px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 96px 48px 64px 48px;

  background-image: ${({ bg }) => (bg ? `url(${bg})` : `url(${HeroBg})`)};
  background-color: ${({ theme }) => theme.colors.white};

  a {
    color: ${({ theme }) => theme.colors.white} !important;
    background-color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.fonts.s.laptop};

    padding: 20px 48px;
    border-radius: 5px;

    &::after {
      display: none;
    }

    &::before {
      display: none;
    }

    &:hover {
      background-color: #323232;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;

    a {
      font-size: ${({ theme }) => theme.fonts.s.tablet};
    }
  }
`;

export const Code = styled.div`
  font-size: 200px;
  font-weight: 700;
  padding: 0px 48px;
  color: ${({ theme }) => theme.colors.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 140px;
    padding: 0;
  }
`;

export const Text = styled.div`
  margin: 36px 0 36px 0;
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 16px;
    margin-top: 16px;
  }
`;
