import React from "react";
import {ButtonText, Icon, IconWrapper, InsideLink, OutsideLink, Pdf, PrimaryButton, StyledLink} from "./styles";
import PdfIcon from "assets/image/pdf-icon.png";

export const Button = ({to, href, type, children, ...otherProps}) => {
  return (
    <>
      {to && (
        <InsideLink className={type} to={to} {...otherProps}>
          {children}
        </InsideLink>
      )}
      {href && (
        <OutsideLink className={type} href={href} {...otherProps}>
          {children}
        </OutsideLink>
      )}
      {!href && !to && (
        <PrimaryButton className={type} {...otherProps}>
          {children}
        </PrimaryButton>
      )}
    </>
  );
};

export const PdfButton = ({href, type, children, ...otherProps}) => {
  return (
    <StyledLink {...otherProps} href={href} target="_blank" rel="noopener">
      <Pdf>
        <IconWrapper>
          <Icon src={PdfIcon} alt=""/>
        </IconWrapper>
        <ButtonText>{children}</ButtonText>
      </Pdf>
    </StyledLink>
  );
};
