import styled, {css, keyframes} from "styled-components";
import {Link} from "gatsby";

//Icons
import ArrowRight from "assets/svg/arrow-right.svg";

const slideLeft = keyframes`
    0% {
        opacity: 0;
        left: 0%;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        left: 40%;
    }
`;

const defaultStyles = css`
    font-size: ${({theme}) => theme.fonts.l.fhd};
    font-weight: ${({theme}) => theme.weights.bold};
    transition: 0.3s background-color ease;
    border: none;
    outline: none;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    position: relative;
    padding: 30px 0;
    background: none;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 3px;
        background-color: ${({theme}) => theme.colors.black};
        transition: width 0.3s ease;
    }

    &::after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        bottom: 0;
        left: -50%;
        display: block;
        background-image: url(${ArrowRight});
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &:hover {
        &::before {
            width: 100%;
        }

        &::after {
            animation: ${slideLeft} 2s infinite;
            animation-delay: 0.1s;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: ${({theme}) => theme.fonts.l.laptop};
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: ${({theme}) => theme.fonts.l.tablet};
        padding: 20px 0;

        &:hover {
            &::before {
                display: none;
            }

            &::after {
                display: none;
            }
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: ${({theme}) => theme.fonts.s.tablet};
    }
`;

export const InsideLink = styled(Link)`
    ${defaultStyles}
`;

export const OutsideLink = styled.a`
    ${defaultStyles}
`;
export const PrimaryButton = styled.button`
    ${defaultStyles}
`;

/// PdfButton

export const StyledLink = styled.a`
    display: inline-block;
`

export const Pdf = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &:hover {
        div {
            &::after {
                opacity: 1;
            }
        }
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        &:hover {
            div {
                &::after {
                    opacity: 0;
                }
            }
        }
    }


    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone_big}) and (orientation: portrait) {
        flex-direction: column;
        margin-right: 0;
    }
`;

export const IconWrapper = styled.div`
    width: 50px;
    height: 50px;
    position: relative;
    flex-shrink: 0;

    &::after {
        content: "";
        position: absolute;
        top: 20%;
        left: 10%;
        width: 80%;
        height: 80%;
        transform: translateY(-50%);
        background-color: #fff200;
        transition: opacity 0.2s ease-in-out;
        opacity: 0;
        z-index: -1;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_big}) {
        width: 42px;
        height: 42px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 38px;
        height: 38px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_small}) {
        width: 32px;
        height: 32px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: 28px;
        height: 28px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone_big}) and (orientation: portrait) {
        margin-bottom: 12px;
    }
`;

export const Icon = styled.img`
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
`;

export const ButtonText = styled.span`
    font-size: ${({theme}) => theme.fonts.m.fhd};
    font-weight: ${({theme}) => theme.weights.bold};
    margin-left: 24px;
    text-align: left;
    line-height: 1.3;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_big}) {
        font-size: ${({theme}) => theme.fonts.m.laptop};
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: ${({theme}) => theme.fonts.s.tablet};
        margin-left: 12px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big})  and (orientation: portrait) {
        font-size: 12px !important;
        text-align: center;
        margin-left: 0;
    }
`;
