import React from "react";
import {Button} from "components/Buttons/Buttons";
import {Code, Container, Text} from "./styles";
import {useSelector} from "react-redux";

const enData = {
  code: "404",
  text:"Page you requested does not exist!",
  button:"Homepage"
}


const ErrorPage = ({code, text, button}) => {
  const {locale} = useSelector(state => state.modals);

  if(locale === 'en'){
    return (
      <Container>
        <Code>{enData.code}</Code>
        <Text>{enData.text}</Text>
        <Button to={locale === "en" ? '/en' : '/'}>{enData.button}</Button>
      </Container>
    );
  }
  return (
    <Container>
      <Code>{code}</Code>
      <Text>{text}</Text>
      <Button to={locale === "en" ? '/en' : '/'}>{button}</Button>
    </Container>
  );
};

export default ErrorPage;
